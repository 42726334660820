body .daterangepicker .title {
	font-size:1.4rem;
	font-weight:600;
	margin:5px 0 10px 5px;
}
body .daterangepicker .legend {
	margin-left:10px;
	margin-bottom:5px;

	clear:both;
}
body .daterangepicker .legend span {
	display:inline-block;
	width:15px;
	height:15px;
	margin-right:5px;
}
body .daterangepicker .legend span.pas_disponible {
	background-color:#B30000;
}

.fleche {
	height: 33px;
	background-repeat: no-repeat;
}

.fleche p{
	color: white;
}